<template>
  <b-overlay
    :show="$apollo.loading"
  >
    <b-card>
      <!-- search input & view switcher -->
      <div class="custom-search d-flex justify-content-between align-items-end">
        <b-form-group
          class="form-inline"
          label="Search"
          label-size="sm"
        >
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block mr-1"
            placeholder="Search Programs"
            type="text"
          />
          <b-button
            :to="{name: 'add-skill-dev-programs'}"
            variant="primary mr-1"
          >
            <feather-icon icon="PlusIcon" />
            Add Program
          </b-button>
        </b-form-group>
        <b-form-group
          label="View as"
          label-for="btn-radios-1"
          label-size="sm"
        >
          <b-form-radio-group
            id="btn-radios-1"
            v-model="view"
            :options="[
              { text: 'Grid', value: 'grid' },
              { text: 'Table', value: 'table' },
            ]"
            buttons
            size="sm"
            button-variant="light"
          />
        </b-form-group>
      </div>
      <!-- table -->
      <section>
        <b-row>
          <b-col cols="12">
            <vue-good-table
              v-if="view === 'table'"
              :columns="columns"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
              :rows="rows"
              :rtl="direction"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
            >

              <template
                slot="table-row"
                slot-scope="props"
              >

                <!-- Column: Name -->
                <span
                  v-if="props.column.field === 'fullName'"
                  class="text-nowrap"
                >
                  <b-avatar
                    :src="props.row.avatar"
                    class="mx-1"
                  />
                  <span class="text-nowrap">{{ props.row.fullName }}</span>
                </span>

                <!-- Column: Status -->
                <span
                  v-else-if="props.column.field === 'status'"
                  class="d-flex justify-content-between align-items-center"
                >
                  <b-badge
                    :variant="statusVariant(props.row.status)"
                    class="border"
                  >
                    {{ props.row.status }}
                  </b-badge>
                  <b-dropdown
                    size="sm"
                    toggle-class="text-decoration-none"
                    variant="link"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <span class="align-middle text-nowrap">Update Status<feather-icon
                        class="align-middle ml-25"
                        icon="ChevronDownIcon"
                      /></span>
                    </template>
                    <b-dropdown-item
                      v-if="props.row.status === 'draft'"
                      :disabled="props.row.status === 'scheduled'"
                      variant="primary"
                      @click="updateProgramStatus(props.row.id, 'scheduled')"
                    >
                      <feather-icon
                        class="mr-50"
                        icon="ClockIcon"
                      />
                      <span>Schedule Program</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      variant="success"
                      :disabled="props.row.status === 'complete'"
                      @click="updateProgramStatus(props.row.id, 'complete')"
                    >
                      <feather-icon
                        class="mr-50"
                        icon="CheckCircleIcon"
                      />
                      <span>Mark as Complete</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      variant="danger"
                      :disabled="props.row.status === 'discontinued'"
                      @click="updateProgramStatus(props.row.id, 'discontinued')"
                    >
                      <feather-icon
                        class="mr-50"
                        icon="StopCircleIcon"
                      />
                      <span>Discontinue Program</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>

                <span v-else-if="props.column.field === 'buttons'">
                  <b-button
                    variant="outline-primary"
                    class="mr-50"
                    size="sm"
                    :to="{name: 'skill-dev-program-manager', params: {id: props.row.id}}"
                  >Program Manager</b-button>
                  <b-button
                    variant="outline-primary"
                    class="mr-50"
                    size="sm"
                    :to="{name: 'skill-dev-application-manager', params: {id: props.row.id}}"
                  >Application Manager</b-button>
                  <b-button
                    variant="link"
                    size="sm"
                    _target="_blank"
                    :to="{name: 'view-programs', params: {
                      id:props.row.id
                    }}"
                  >View Program
                  </b-button>
                  <b-button
                    variant="flat-danger"
                    size="sm"
                    @click="pid=props.row.id;$bvModal.show('deleteModal')"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      :per-page="pageLength"
                      :total-rows="props.total"
                      :value="1"
                      class="mt-1 mb-0"
                      first-number
                      last-number
                      next-class="next-item"
                      prev-class="prev-item"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </section>
      <!-- card view -->
      <section
        v-if="view === 'grid'"
        class="grid-view mt-1"
      >
        <b-row>
          <b-col
            v-for="(row, i) in rows"
            :key="i"
            :title="row.title"
            md="4"
            style="margin-bottom: 10px"
          >
            <b-card
              :key="i"
              header-tag="header"
              tag="article"
              class="ecommerce-card border border-secondary"
              no-body
            >
              <Promised
                v-if="row.image"
                :promise="getLinkFromS3(row.image)"
              >
                <template v-slot:pending>
                  <b-card-img
                    class="card-img"
                    src="https://picsum.photos/600/300"
                  />
                </template>
                <template v-slot="data">
                  <b-aspect
                    :style="`background: url(${data}) center center / cover no-repeat`"
                    aspect="2"
                  />
                </template>
                <template v-slot:rejected>
                  <b-card-img
                    class="card-img"
                    src="https://picsum.photos/600/300"
                  />
                </template>
              </Promised>
              <b-card-img
                v-else

                class="card-img"
                src="https://picsum.photos/600/300"
              />
              <b-card-body>
                <b-card-title>
                  <div class="d-flex justify-content-between">
                    <span>
                      {{ row.title }}
                    </span>
                    <b-link
                      :to="{name: 'view-programs', params: {id: row.id}}"
                    >
                      <b-button
                        class="ml-2"
                        size="sm"
                        variant="primary"
                        @click.stop="viewProgram(row.id)"
                      >
                        View
                      </b-button>
                    </b-link>
                  </div>
                </b-card-title>

                <b-card-text>
                  {{ row.description }}
                </b-card-text>
                <div class="d-flex align-items-baseline justify-content-between">
                  <b-card-text><strong>Date : {{ beginDate(row.begin_date) }}</strong></b-card-text>
                  <b-button
                    size="sm"
                    variant="outline-primary"
                    @click="pid=row.id;$bvModal.show('schedulemodal')"
                  >
                    <span v-if="!row.begin_date">Schedule</span>
                    <span v-else>Re-Schedule</span>
                  </b-button>
                </div>
                <div class="d-flex align-items-baseline justify-content-between">
                  <b-card-text><strong>Duration : {{ (row.duration) }}</strong></b-card-text>
                  <b-badge
                    :variant="statusVariant(row.status)"
                    class="border"
                  >
                    {{ row.status }}
                  </b-badge>
                </div>

                <!--              <b-card-text><strong>Capacity of Program : {{ (row.capacity) }}</strong></b-card-text>-->
                <b-card-footer class="p-0">
                  <div class="d-flex justify-content-between mt-2">
                    <b-link
                      :to="{name: 'skill-dev-application-manager', params: {id: row.id}}"
                      class="btn btn-outline-primary rounded"
                    >
                      <span>Applications</span>
                    </b-link>
                    <b-link
                      :to="{name: 'skill-dev-program-manager', params: {id: row.id}}"
                      class="btn btn-primary rounded"
                      variant="light"
                    >
                      <span>Manager</span>
                    </b-link>
                  </div>
                </b-card-footer>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-between align-items-center  flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLengthGrid"
              :options="['9', '27', '81']"
              class="mx-1"
              @input="updatePageLength"
            />
            <span class="text-nowrap"> of {{ rows.length }} entries </span>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :value="1"
              :per-page="pageLengthGrid"
              :total-rows="totalItems"
              class="mt-1 mb-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
              @input="(value) => onPageChange(value)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </section>
      <b-modal
        id="deleteModal"
        ok-title="Yes, Delete"
        ok-variant="danger"
        button-size="sm"
        no-close-on-backdrop
        centered
        size="sm"
        title="Confirm Delete"
        @ok="deleteProgram()"
      >
        <h6>Are you sure you want to delete this program?</h6>
      </b-modal>
      <b-modal
        id="schedulemodal"
        ok-title="Update"
        ok-variant="success"
        button-size="sm"
        no-close-on-backdrop
        centered
        size="sm"
        title="Update Program Date"
        @ok="scheduleprogram()"
      >
        <flat-pickr
          id="programDate"
          v-model="begin_date"
          :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
          class="form-control"
          placeholder="Select Start Date and Time"
        />
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BAspect,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardFooter,
  BCardImg,
  BCardText,
  BCardTitle,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormSelect,
  BLink,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import gql from 'graphql-tag'
import flatPickr from 'vue-flatpickr-component'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BAspect,
    BCardImg,
    BFormRadioGroup,
    BRow,
    BCol,

    flatPickr,
    BCardText,
    BCardTitle,
    BLink,
    BCard,
    BCardFooter,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      view: 'grid',
      pageLengthGrid: 9,
      pageLength: 5,
      dir: false,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: '',
          field: 'buttons',
          sortable: false,
        },

      ],
      rows: [],
      begin_date: null,
      pid: null,
      searchTerm: '',
    }
  },
  computed: {
    totalItems() {
      return this.rows.length
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.pageLength)
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        scheduled: 'light-primary',
        discontinued: 'light-danger',
        draft: 'light-secondary',
        complete: 'light-success',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    userOrgs() {
      const arr = getUserData()
        .associatedOrgDetails
      return arr.map(e => e.organization_id)
        .filter(obj => obj) // Remove nulls
    },
  },
  methods: {
    scheduleprogram() {
      this.$apollo.mutate({
        mutation: gql`
          mutation {
             update_programs_basicinfo_by_pk(pk_columns: {id: ${this.pid}}, _set: {begin_date: "${this.begin_date}"}) {
              id
              status
            }
          }`,
        update: () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Program Updated Successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    onPageChange(value) {
      this.currentPage = value
    },
    updateView(view) {
      this.view = view
    },
    beginDate(date) {
      if (!date) return ''
      const dateNew = new Date(date)
      return this.$moment(dateNew)
        .format('MMM DD, YYYY')
    },
    // GraphQL Mutate update program status as discontinued
    updateProgramStatus(id, status) {
      this.$apollo.mutate({
        mutation: gql`
          mutation updateProgramStatus($id: Int!, $status: String!) {
             update_programs_basicinfo_by_pk(pk_columns: {id: $id}, _set: {status: $status}) {
              id
              status
            }
          }`,
        variables: {
          id,
          status,
        },
      })
        .then(({ data }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Program status updated to ${data.update_programs_basicinfo_by_pk.status}`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$apollo.queries.rows.refetch()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating program status',
              icon: 'ErrorIcon',
              variant: 'danger',
            },
          })
        })
    },

  },
  apollo: {
    rows: {
      query() {
        return gql`
        query MyQuery {
        programs_basicinfo(order_by: {date_created: desc}, where: {organization_id_id: {_in: [${this.userOrgs}]}, is_skilldev: {_eq: true}}) {
          id,
          title,
          description,
          status,
          type,
          image,
          begin_date,
          duration,
          capacity,
          programs_startupparticipants
          {
            programs_applicantstable {
              users_organizationtable {
                title
              }
            }
          }
          users_associationtables {
            users_customuser {
              full_name
            }
          }
        }
      }`
      },
      update: data => data.programs_basicinfo,
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
